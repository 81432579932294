import React from 'react'
import footerLogo from '../Images/madkpi_white.png'
import linkedin from '../Images/linkedin.png'
import instagram from '../Images/instagram.png'
import twitter from '../Images/twitter.png'
import facebook from '../Images/facebook.png'
const Footer = () => {
    const handleRedirect = () => {
        window.open('https://calendly.com/analyticsliv/30min', '_blank');
    };

    const handleSmoothScroll = (event, sectionId) => {
        event.preventDefault();
        document.getElementById(sectionId).scrollIntoView({
            behavior: 'smooth'
        });
    };
    return (
        <div className='md:p-8 lg:p-16 max-sm:py-10 flex text-white md:gap-[5%] lg:gap-[10%] flex-wrap max-md:justify-center'>
            <div className='md:w-2/5 lg:w-1/3 flex flex-col gap-7 max-sm:gap-1 max-md:items-center'>
                <div className='sm:pt-5 flex gap-2 md:gap-4'>
                    <a href='/home'><img src={footerLogo} className='h-12 w-44 rounded-2xl px-2 py-1' alt='Logo' /></a>
                </div>
                {/* <div className='sm:pt-4 max-md:text-center max-sm:px-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of </div> */}
                <div>
                    <div className='flex gap-5 max-md:hidden'>
                        <a href='https://www.linkedin.com/company/76458239/admin/dashboard/' target='_blank' ><img src={linkedin} alt="" className='cursor-pointer' /></a>
                        <a href='https://www.instagram.com/analyticsliv_digital/' target='_blank' ><img src={instagram} alt="" className='cursor-pointer' /></a>
                        <a href='https://x.com/AnalyticsLiv' target='_blank' ><img src={twitter} alt="" className='cursor-pointer' /></a>
                        <a href='https://www.facebook.com/people/AnalyticsLiv/100070503960704/' target='_blank' ><img src={facebook} alt="" className='cursor-pointer' /></a>
                    </div>
                </div>
                <div className=' max-md:hidden'>Copyright @ 2023 AnalyticsLiv</div>
            </div>
            <div className='w-[90dvw] md:w-1/2 xl:w-2/5 max-xl:pt-5 max-sm:mt-7'>
                <div className='flex justify-between border-b pb-3 sm:pb-4 xl:pb-4 border-b-white '>
                    <div className='text-lg sm:text-xl lg:text-2xl xl:text-3xl font-normal pt-2 sm:pt-2'>Schedule a Call Now</div>
                    <div>
                        <button onClick={(e) => handleSmoothScroll(e, 'form')} className='bg-white max-sm:px-4 px-7 py-2 text-black border rounded-3xl font-medium hover:text-white hover:bg-transparent transition-all duration-200 ease-linear'>Contact Us</button>
                    </div>
                </div>
                <div className='pt-8 pb-8'>
                    <div className='flex justify-between'>
                        <div className='flex flex-col gap-3'>
                            <a className='cursor-pointer text-lg font-medium'>Home</a>
                            <a href="#Benefits" onClick={(e) => handleSmoothScroll(e, 'Benefits')} className='cursor-pointer text-base font-normal pt-3'>Benefits</a>
                            <a href="#Features" onClick={(e) => handleSmoothScroll(e, 'Features')} className='cursor-pointer text-base font-normal '>Features</a>
                            <a href="#Process" onClick={(e) => handleSmoothScroll(e, 'Process')} className='cursor-pointertext-base font-normal '>Process</a>
                        </div>
                        <div className='flex flex-col gap-3'>
                            <a className='cursor-pointer text-lg font-medium'>Help</a>
                            <a className='cursor-pointer text-base font-normal pt-3'>Privacy Policy</a>
                            <a className='cursor-pointer text-base font-normal '>Term & Condition</a>
                        </div>
                        <div className='flex flex-col gap-3 sm:pr-[7%]'>
                            <a className='cursor-pointer text-lg font-medium'>Social</a>
                            <a href='https://www.linkedin.com/company/76458239/admin/dashboard/' target='_blank' className='cursor-pointer text-base font-normal pt-3'>LinkedIn</a>
                            <a href='https://x.com/AnalyticsLiv' target='_blank' className='cursor-pointer text-base font-normal '>Twitter</a>
                            <a href='https://www.instagram.com/analyticsliv_digital/' target='_blank' className='cursor-pointer text-base font-normal '>Instagram</a>
                            <a href='https://www.facebook.com/people/AnalyticsLiv/100070503960704/' target='_blank' className='cursor-pointer text-base font-normal '>Facebook</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-center items-center pt-6 gap-5'>
                <div className='flex gap-5 md:hidden'>
                    <a href='https://www.linkedin.com/company/76458239/admin/dashboard/' target='_blank' ><img src={linkedin} alt="" className='cursor-pointer' /></a>
                    <a href='https://www.instagram.com/analyticsliv_digital/' target='_blank' ><img src={instagram} alt="" className='cursor-pointer' /></a>
                    <a href='https://x.com/AnalyticsLiv' target='_blank' ><img src={twitter} alt="" className='cursor-pointer' /></a>
                    <a href='https://www.facebook.com/people/AnalyticsLiv/100070503960704/' target='_blank' ><img src={facebook} alt="" className='cursor-pointer' /></a>
                </div>

                <div className='md:hidden text-[14px] text-center'>Copyright @ 2023 AnalyticsLiv</div>
            </div>
        </div>
    )
}

export default Footer
