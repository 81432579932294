import { useState, useEffect } from 'react';
import headerLogo from '../Images/logo.png'
import logo from '../Images/image (10).png'
import cross from '../Images/Cross.png'
import menuToggle from '../Images/menu.svg'

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const [header, setHeader] = useState(75);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const visible = prevScrollPos > currentScrollPos;
    setPrevScrollPos(currentScrollPos);
  };

  const handleSmoothScroll = (event, sectionId) => {
    event.preventDefault();
    document.getElementById(sectionId).scrollIntoView({
      behavior: 'smooth'
    });
    setToggle(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {

    let headerSize = () => {

      const totalScroll = document.documentElement.scrollTop;

      if ((totalScroll > 130)) {
        setHeader(60);
      } else if ((totalScroll < 100)) {
        setHeader(75);
      }


    }
    window.addEventListener("scroll", headerSize);
    return () => window.removeEventListener("scroll", headerSize);
  });

  return (
    <>
      <header className={toggle ? ' mb-14 max-sm:mb-24' : 'w-full'}  >
        <div style={{ height: header }} className='pt-4 sm:px-12'>
          <div className='flex justify-between'>
            <h1>
              <a href="/home">
                <img className="h-12 w-44 bg-white rounded-[20px] px-3 py-0.5" src={logo} alt="logo" />
              </a>
            </h1>
            {/* <div className="flex justify-between "> */}
              <div className='flex justify-between md:mr-[5dvw] xl:justify-end md:gap-5 md:ml-3 lg:ml-0 lg:gap-10 text-white font-bold text-lg mt-3 max-md:hidden'>
                <a href="#Benefits" className='cursor-pointer' onClick={(e) => handleSmoothScroll(e, 'Benefits')}>Benefits</a>
                <a href="#Features" className='cursor-pointer' onClick={(e) => handleSmoothScroll(e, 'Features')}>Features</a>
                <a href="#Process" className='cursor-pointer' onClick={(e) => handleSmoothScroll(e, 'Process')}>Process</a>
              </div>
              {/* <a href='https://adsalert.analyticsliv.com/login' target='_blank'>

                <button className=" max-md:hidden text-lg font-bold md:px-6 lg:px-8 py-2 bg-white text-[#1C4645] border-2 max-h-12 transition-all duration-300 ease-linear max-sm:w-fit border-white sm:px-4 max-sm:mr-2 rounded-3xl hover:bg-transparent hover:text-white">
                  Log in
                </button>
              </a> */}
            {/* </div> */}
            <div className="md:hidden fill-white">
              <a href="#" onClick={() => setToggle(!toggle)}>
                <img className="float-right right-0" src={toggle ? cross : menuToggle} alt="menu toggle" />              </a>
              {toggle && (
                <div className="flex flex-col items-center gap-2 md:gap-4 font-bold  mt-14 mb-10 py-2 overflow-hidden md:hidden bg-white text-black max-md:w-[40vw]">
                  <a href="#Benefits" className='pt-0' onClick={(e) => handleSmoothScroll(e, 'Benefits')}>Benefits</a>
                  <a href="#Features" onClick={(e) => handleSmoothScroll(e, 'Features')}>Features</a>
                  <a href="#Process" onClick={(e) => handleSmoothScroll(e, 'Process')}>Process</a>
                  {/* <a href='https://adsalert.analyticsliv.com/login'>
                    <button className=" text-[#30A953] border-2  max-sm:w-fit border-white rounded-lg hover:bg-transparent hover:text-white">
                      Log in
                    </button>
                  </a> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;