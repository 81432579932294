import React, { useState } from 'react';
import upArrow from '../Images/upwards-faqs.png'
import downArrow from '../Images/downwards-faqs.png'

const faqs = [
  {
    question: "Do we need to share access to our DV360 account for using MAdKPI ?",
    answer: "Absolutely, the mechanism of MAdKPI utilised APIs to extract data in real time from the reports.",
  },
  {
    question: "How many alerts can we configure?",
    answer: "Currently, there is no limit to number of alerts, however we would recommend using it for upto 10 alerts only",
  },
  {
    question: "Can MAdKPI pause our advertiser based on present budget conditions?",
    answer: "MAdKPI uses two different levels of control for managing the cap on maximum budget expenditure - Monthly and Daily. Any breach on those limits will trigger a pause for the advertiser",
  },
  {
    question: "Can MAdKPI inform us before pausing the campaigns ?",
    answer: "MAdKPI ensures that you are alerted about your monthly budgets everytime the remaining amount is for less than 4 days of daily budget limit set manually. This feature ensures a timely course correction and refill.",
  },
  {
    question: "Can MAdKPI also showcase real time spends and remaining budgets ?",
    answer: "MAdKPI uses utmost transparency with its clients and therefore shows the budgets and expenditures in real time.",
  },
  {
    question: "Is it possible to share the access of MAdKPI to someone else in the team ?",
    answer: "Yes, absolutely.",
  },
];

const FAQItem = ({ faq, isOpen, toggle }) => (
  <div className="py-2 bg-[#1C4645] w-[90dvw] sm:w-[85dvw] ">
    <div className="flex justify-between items-center cursor-pointer bg-[#CBF7F6] rounded-[5px] px-4" onClick={toggle}>
      <h3 className="text-lg font-medium py-1">{faq.question}</h3>
      <img src={isOpen ? upArrow : downArrow} alt="Toggle arrow" className="w-6 h-6" />
    </div>
    {isOpen && <p className="mt-1 py-1 px-4 text-sm font-normal bg-white rounded-[5px]">{faq.answer}</p>}
  </div>
);

const FAQList = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="p-5  flex flex-col items-center justify-center">
      {faqs.map((faq, index) => (
        <FAQItem
          key={index}
          faq={faq}
          isOpen={openIndex === index}
          toggle={() => toggleFAQ(index)}
        />
      ))}
    </div>
  );
};

export default FAQList;
