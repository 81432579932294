import './App.css';
import Landing from './Components/madkpi-landing';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-NLH25K8',
};

function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);




  return (
    <div className="">
      {/* <header className="App-header"> */}
      <Landing />
      {/* </header> */}
    </div>
  );
}

export default App;
